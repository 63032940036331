import PropTypes from 'prop-types';
import ManageLettersColumnPage from './ManageLettersColumnPage';

const columnHeaders = {
  UNPREPPED: 'Unprepared',
  PREPPED: 'Prepared',
  SENT: 'Sent',
};

function ManageLettersColumn({ bundleCount, letterCount, letterFilter, modifyAndDownloadState, canAdoptSocial, status }) {
  return (
    <div className='col-sm-6 col-md-4 mb-4 mb-md-0 letters-col'>
      <div className='pb-3'>
        <span className='pt-2 mb-4 h5'>{ columnHeaders[status] } </span>
        <span className='fs-6 fw-normal'>{ `(${ letterCount } letters in ${ bundleCount } ${ parseInt(bundleCount) === 1 ? 'bundle' : 'bundles' })` }</span>
      </div>
      <ManageLettersColumnPage canAdoptSocial={ canAdoptSocial } isFirstPage letterFilter={ letterFilter } modifyAndDownloadState={ modifyAndDownloadState } status={ status } />
    </div>
  );
}

ManageLettersColumn.propTypes = {
  bundleCount: PropTypes.number.isRequired,
  canAdoptSocial: PropTypes.bool.isRequired,
  letterCount: PropTypes.number.isRequired,
  letterFilter: PropTypes.string.isRequired,
  modifyAndDownloadState: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
};

export default ManageLettersColumn;
