import { useForm } from 'react-hook-form';
import { Fragment } from 'react';

import LetterKitRequestConfirmation from './LetterKitRequestConfirmation';
import { isDatePast } from '../lib/dates.js';
import Loading from '../components/Loading';
import PropTypes from 'prop-types';
import StyledErrorMessage from '../forms/StyledErrorMessage';
import { letterKitType } from './letterKitTypes';
import { useCampaigns, useLetterKitMutation, useLetterKits, useUser } from '../lib/queries/queries';

import '../scss/letter-kits/_letter-kit-request-form.scss';

function LetterKitRequestForm({ isFullKitAvailable, isPostageOnlyKitAvailable, kitRequestLimit }) {
  const letterKitCampaigns = useCampaigns(
    {},
    {
      select: (campaigns) => [...campaigns]
        .filter((campaign) => campaign.letter_kit_mode
          && !isDatePast(campaign.mail_date)
          && (campaign.available_voter_count > 0))
        .sort((a, b) => a.display_name.localeCompare(b.display_name)),
    },
  );
  const letterKits = useLetterKits();
  const user = useUser();

  const {
    formState: { errors: formErrors, isSubmitted: isFormSubmitted },
    handleSubmit: validateForm,
    register,
  } = useForm({
    criteriaMode: 'all', // This is needed to render messages [https://react-hook-form.com/api/useformstate/errormessage]
    shouldUseNativeValidation: false,
  });

  const {
    error: letterKitRequestError,
    isError: isLetterKitRequestError,
    isLoading: isLetterKitRequestLoading,
    mutate: requestLetterKit,
  } = useLetterKitMutation();

  let submitButtonText = 'Submit Request';
  if (isLetterKitRequestLoading) {
    submitButtonText = 'Submitting...';
  } else if (letterKits.isFetching || letterKits.isLoading) {
    submitButtonText = 'Loading Letter Kits...';
  }

  const handleSubmit = validateForm(async (data) => await requestLetterKit(data));

  function formInputClassNames(name, controlClassName = 'form-control') {
    let validationClassName;
    if (isFormSubmitted && formErrors) {
      validationClassName = formErrors[name] ? 'is-invalid' : 'is-valid';
    }
    return [controlClassName, validationClassName].filter(Boolean).join(' ');
  }

  if (letterKits.isLoading || letterKitCampaigns.isLoading || user.isLoading) {
    return <Loading />;
  }

  const letterKitsHaveBeenRequested = letterKits.data.length >= 1;
  const nonRejectedKits = letterKits.data.filter(((letterKit) => letterKit.status !== 'REJECTED'));

  const requestedKitsAboveLimit = letterKitsHaveBeenRequested && (nonRejectedKits.length >= kitRequestLimit);

  if (requestedKitsAboveLimit) {
    return <LetterKitRequestConfirmation email={ user.data.email } kits={ letterKits.data } />;
  }

  return (
    <Fragment>
      {
        (letterKitsHaveBeenRequested)
          ? <LetterKitRequestConfirmation email={ user.data.email } kits={ letterKits.data } />
          : null
      }

      <p>
        While your application is very welcome, we ask that you refrain from applying if you don&rsquo;t need the help,
        so that others may avail themselves of the program.
      </p>

      <form className='letter-kit-request' noValidate onSubmit={ handleSubmit }>
        <fieldset className='form-row mb-2'>
          <div className='col-8 ps-1 pe-2'>
            <label className='fw-bold mb-1 small' htmlFor='districtId'>
              Choose a campaign
            </label>
            <select
              className={ formInputClassNames('districtId') }
              id='districtId'
              title='Please select a campaign to write letters for'
              {
              ...register(
                'districtId',
                { required: 'Please select a campaign to write letters for' },
              )
              }
            >
              {
                letterKitCampaigns.data?.map((campaign) => (
                  <option key={ campaign.id } value={ campaign.district_id }>
                    { campaign.display_name }
                  </option>
                ))
              }
            </select>
          </div>
          <div className='col-8 ps-1 pe-2'>
            <label className='fw-bold mb-1 small' htmlFor='kitType'>
              What type of kit would you like?
            </label>
            <select
              className={ formInputClassNames('kitType') }
              id='kitType'
              title='What type of kit would you like?'
              {
              ...register(
                'kitType',
                { required: 'Please select a letter kit type' },
              )
              }
            >
              <option
                disabled={ !isFullKitAvailable }
                value='FULL'
              >Full kit (stamps, letters, envelopes)
              </option>
              <option
                disabled={ !isPostageOnlyKitAvailable }
                value='STAMPS_ONLY'
              >Postage-only kit (just the stamps)
              </option>
            </select>
          </div>
        </fieldset>
        <h3 className='h3 headline'>
          Application Form
        </h3>
        <fieldset className='form-row mb-0'>
          <div className='col-8 ps-1 pe-2 py-2'>
            <label className='fw-bold mb-1 small' htmlFor='preferredName'>
              Name
            </label>
            <input
              className={ formInputClassNames('preferredName') }
              defaultValue={ user.data.full_name }
              id='preferredName'
              placeholder='Your preferred name for mailing the letter kit'
              title='Please enter your preferred name for mailing the letter kit'
              type='text'
              { ...register('preferredName', { required: 'Please enter your preferred name for mailing the letter kit' }) }
            />
            <StyledErrorMessage errors={ formErrors } name='preferredName' />
          </div>
          <div className='col-4 p-2'>
            <label className='fw-bold mb-1 small' htmlFor='email'>
              Email address
            </label>
            <input
              className='form-control'
              id='email'
              placeholder={ user.data.email }
              readOnly
              title='To change your email address, visit your profile'
              type='email'
              value={ user.data.email }
              { ...register('email', { required: true }) }
            />
          </div>
        </fieldset>
        <fieldset className='form-row mb-0'>
          <div className='col-4 pb-2 ps-1 pe-2 pt-1'>
            <label className='fw-bold mb-1 small' htmlFor='address'>
              Mailing address
            </label>
            <input
              className={ formInputClassNames('address') }
              id='address'
              placeholder='Your mailing address'
              title='Please enter your mailing address'
              type='text'
              { ...register('address', { required: 'Please enter your mailing address' }) }
            />
            <StyledErrorMessage errors={ formErrors } name='address' />
          </div>
          <div className='col-4 pb-2 pt-1 px-2'>
            <label className='fw-bold mb-1 small' htmlFor='city'>
              City
            </label>
            <input
              className={ formInputClassNames('city') }
              id='city'
              placeholder='Your city'
              title='Please enter your mailing city'
              type='text'
              { ...register('city', { required: 'Please enter your mailing city' }) }
            />
            <StyledErrorMessage errors={ formErrors } name='city' />
          </div>
          <div className='col-2 pb-2 pt-1 px-2'>
            <label className='fw-bold mb-1 small' htmlFor='state'>
              State
            </label>
            <select
              className={ formInputClassNames('state') }
              id='state'
              title='Please choose your mailing state'
              {
              ...register(
                'state',
                {
                  maxLength: 2,
                  minLength: 2,
                  pattern: /[A-Z]{2}/,
                  required: 'Please choose your mailing state',
                },
              )
              }
            >
              <option title='Please choose your mailing state' value='' />
              <option title='Alabama' value='AL'>AL</option>
              <option title='Alaska' value='AK'>AK</option>
              <option title='Arizona' value='AZ'>AZ</option>
              <option title='Arkansas' value='AR'>AR</option>
              <option title='California' value='CA'>CA</option>
              <option title='Colorada' value='CO'>CO</option>
              <option title='Connecticut' value='CT'>CT</option>
              <option title='Delaware' value='DE'>DE</option>
              <option title='District of Colombia' value='DC'>DC</option>
              <option title='Florida' value='FL'>FL</option>
              <option title='Georgia' value='GA'>GA</option>
              <option title='Hawaii' value='HI'>HI</option>
              <option title='Idaho' value='ID'>ID</option>
              <option title='Illinois' value='IL'>IL</option>
              <option title='Indiana' value='IN'>IN</option>
              <option title='Iowa' value='IA'>IA</option>
              <option title='Kansas' value='KS'>KS</option>
              <option title='Kentucky' value='KY'>KY</option>
              <option title='Louisiana' value='LA'>LA</option>
              <option title='Maine' value='ME'>ME</option>
              <option title='Maryland' value='MD'>MD</option>
              <option title='Massachusetts' value='MA'>MA</option>
              <option title='Michigan' value='MI'>MI</option>
              <option title='Minnesota' value='MN'>MN</option>
              <option title='Mississippi' value='MS'>MS</option>
              <option title='Missouri' value='MO'>MO</option>
              <option title='Montana' value='MT'>MT</option>
              <option title='Nebraska' value='NE'>NE</option>
              <option title='Nevada' value='NV'>NV</option>
              <option title='New Hampshire' value='NH'>NH</option>
              <option title='New Jersey' value='NJ'>NJ</option>
              <option title='New Mexico' value='NM'>NM</option>
              <option title='New York' value='NY'>NY</option>
              <option title='North Carolina' value='NC'>NC</option>
              <option title='North Dakota' value='ND'>ND</option>
              <option title='Ohio' value='OH'>OH</option>
              <option title='Oklahoma' value='OK'>OK</option>
              <option title='Oregon' value='OR'>OR</option>
              <option title='Pennsylvania' value='PA'>PA</option>
              <option title='Puerto Rico' value='PR'>PR</option>
              <option title='Rhode Island' value='RI'>RI</option>
              <option title='South Carolina' value='SC'>SC</option>
              <option title='South Dakota' value='SD'>SD</option>
              <option title='Tennessee' value='TN'>TN</option>
              <option title='Texas' value='TX'>TX</option>
              <option title='Utah' value='UT'>UT</option>
              <option title='Vermont' value='VT'>VT</option>
              <option title='Virginia' value='VA'>VA</option>
              <option title='Washington' value='WA'>WA</option>
              <option title='West Virginia' value='WV'>WV</option>
              <option title='Wisconsin' value='WI'>WI</option>
              <option title='Wyoming' value='WY'>WY</option>
            </select>
            <StyledErrorMessage errors={ formErrors } name='state' />
          </div>
          <div className='col-2 pb-2 pt-1 px-2'>
            <label className='fw-bold mb-1 small' htmlFor='zip_code'>
              ZIP code
            </label>
            <input
              className={ formInputClassNames('zip_code') }
              id='zip_code'
              placeholder='Your ZIP code'
              title='Please enter your ZIP code'
              type='text'
              { ...register(
                'zip_code',
                {
                  maxLength: {
                    message: 'Please enter 5 or 9 digits',
                    value: 10,
                  },
                  minLength: {
                    message: 'Please enter 5 or 9 digits',
                    value: 5,
                  },
                  pattern: {
                    message: 'Please enter your 5 digit ZIP code or ZIP+4',
                    value: /\d{5}(?:-\d{4})?/,
                  },
                  required: 'Please enter your ZIP code',
                },
              ) }
            />
            <StyledErrorMessage errors={ formErrors } name='zip_code' />
          </div>
        </fieldset>
        <fieldset className='form-row mb-0'>
          <div className='col pb-2 ps-1 pe-2 pt-1'>
            <label className='fw-bold mb-1 small' htmlFor='statement'>
              Personal statement
            </label>
            <textarea
              className={ formInputClassNames('statement') }
              id='statement'
              placeholder='Please tell us a bit about why you are requesting a letter kit'
              rows={ 2 }
              title='Please tell us a bit about why you are requesting a letter kit'
              { ...register('statement', { required: 'Please tell us a bit about why you are requesting a letter kit' }) }
            />
            <StyledErrorMessage errors={ formErrors } name='statement' />
          </div>
        </fieldset>
        <fieldset className='form-row mb-2'>
          <div className='col pb-2 ps-1 pe-2 pt-1'>
            <div className='form-check'>
              <input
                className={ formInputClassNames('confirmedTermsAt', 'form-check-input') }
                id='confirmedTermsAt'
                title='Please confirm that you will abide by the terms of the letter kit program'
                type='checkbox'
                { ...register('confirmedTermsAt', { required: 'Please confirm that you will abide by the terms of the letter kit program' }) }
              />
              <label className='form-check-label' htmlFor='confirmedTermsAt'>
                I agree to use the supplies I receive according to the instructions, and
                then mail the letters on the mail date.
              </label>
              <StyledErrorMessage errors={ formErrors } name='confirmedTermsAt' />
            </div>
          </div>
        </fieldset>
        {
          isLetterKitRequestError
            ? (
              <fieldset className='mb-3 form-row justify-content-center'>
                <div className='col'>
                  <h3 className='text-error fw-bold h5'>
                    { letterKitRequestError.response.data.message }
                  </h3>
                </div>
              </fieldset>
              )
            : null
        }
        <fieldset className='mb-3 form-row justify-content-center'>
          <div className='col-4 p-2'>
            <input
              className='btn btn-primary-red w-100'
              disabled={ isLetterKitRequestLoading || letterKits.isLoading || letterKits.isFetching }
              type='submit'
              value={ submitButtonText }
            />
          </div>
        </fieldset>
        <fieldset>
          <input type='hidden' value={ 20 } { ...register('bundleSize', { valueAsNumber: true }) } />
          <input type='hidden' value={ letterKitType } { ...register('type') } />
        </fieldset>
      </form>
    </Fragment>
  );
}


LetterKitRequestForm.propTypes = {
  isFullKitAvailable: PropTypes.bool.isRequired,
  isPostageOnlyKitAvailable: PropTypes.bool.isRequired,
  kitRequestLimit: PropTypes.number.isRequired,
};

export default LetterKitRequestForm;
