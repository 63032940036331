import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import Loading from '../../components/Loading';

import BundleListItem from './BundleListItem';
import EmptyColumn from './EmptyColumn';
import { useBundleData } from '../../lib/queries/queries';
import BundleVisibilityChecker from './BundleVisibilityChecker';

function shouldShowLetterInfo(bundle) {
  if (bundle.social_token) {
    return bundle.social_assignee_email && (bundle.bundle_status === 'UNPREPPED');
  }
  return true;
}

function ManageLettersColumnPage({
  isParentReady = true,
  modifyAndDownloadState,
  canAdoptSocial,
  letterFilter,
  status,
  offsetData = { timestamp: null, bundleId: null },
  pageIndex = 0,
  isFirstPage = false,
  previousKeysets = [],
}) {
  const { isLoading, isSuccess, isFetching, data } = useBundleData({ status, offsetData, letterFilter });
  const [isVisible, setIsVisible] = useState(isFirstPage);
  const letterFilterPresent = !!letterFilter && (letterFilter !== '');

  if (!isVisible) {
    return (<BundleVisibilityChecker isParentReady={ isParentReady } setIsVisible={ setIsVisible } />);
  }

  if (isLoading) {
    return (<Loading />);
  }

  if (!isSuccess || !data) {
    // TODO: display error message
    return null;
  }

  const isReady = isParentReady && !isFetching && !isLoading;

  const { bundles, hasNext } = data;

  const nextOffsetData = { timestamp: null, bundleId: null };
  if (bundles.length > 0) {
    const lastBundleInPage = bundles[bundles.length - 1];
    nextOffsetData.timestamp = lastBundleInPage.bundle_adopted_at;
    nextOffsetData.bundleId = lastBundleInPage.bundle_id;

    if (previousKeysets.includes(`${ nextOffsetData.timestamp }${ nextOffsetData.bundleId }`)) {
      // TODO: display error message
      return null;
    }
  } else if (isFirstPage) {
    return (
      <EmptyColumn
        letterFilterPresent={ letterFilterPresent }
        status={ status }
      />
    );
  }

  return (
    <Fragment>
      { bundles.map((bundle, bundleIndex) => (
        <BundleListItem
          bundle={ bundle }
          canAdoptSocial={ canAdoptSocial }
          isReady={ isReady }
          key={ bundle.hashid }
          modifyAndDownloadState={ modifyAndDownloadState }
          shouldShowLetterInfo={ shouldShowLetterInfo }
          status={ status }
          testLabel={ `bundle-${ pageIndex }-${ bundleIndex }-${ status }` }
        />
      )) }
      { hasNext && nextOffsetData.timestamp && nextOffsetData.bundleId && (
        <ManageLettersColumnPage
          canAdoptSocial={ canAdoptSocial }
          isParentReady={ isReady }
          letterFilter={ letterFilter }
          modifyAndDownloadState={ modifyAndDownloadState }
          offsetData={ nextOffsetData }
          pageIndex={ pageIndex + 1 }
          previousKeysets={ [...previousKeysets, `${ nextOffsetData.timestamp }${ nextOffsetData.bundleId }`] }
          status={ status }
        />
      ) }
    </Fragment>
  );
}

ManageLettersColumnPage.propTypes = {
  canAdoptSocial: PropTypes.bool.isRequired,
  isFirstPage: PropTypes.bool,
  isParentReady: PropTypes.bool,
  letterFilter: PropTypes.string.isRequired,
  modifyAndDownloadState: PropTypes.object.isRequired,
  offsetData: PropTypes.object,
  pageIndex: PropTypes.number,
  previousKeysets: PropTypes.array,
  status: PropTypes.string.isRequired,
};

export default ManageLettersColumnPage;
