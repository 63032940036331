import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

LetterFilter.propTypes = {
  className: PropTypes.string,
  letterFilter: PropTypes.string,
  updateFilter: PropTypes.func,
};


export default function LetterFilter({
  className,
  letterFilter,
  updateFilter,
}) {
  const [currentFilter, setCurrentFilter] = useState(letterFilter);
  useEffect(() => {
    setCurrentFilter(letterFilter);
  }, [letterFilter]);

  const onFilterChange = (event) => {
    setCurrentFilter(event.target.value);
  };

  const blurOnEnter = (event) => {
    if (event.key === 'Enter') {
      event.target.blur();
    }
  };

  const submitFilter = () => {
    updateFilter(currentFilter);
  };

  return (
    <div className={ className }>
      <div className='row'>
        <input
          aria-label='Filter letters'
          className='col input-col'
          id='filterInput'
          onBlur={ submitFilter }
          onChange={ onFilterChange }
          onKeyDown={ blurOnEnter }
          onSubmit={ (e) => e.preventDefault() }
          placeholder='Search for a voter, campaign, or bundle ID'
          type='text'
          value={ currentFilter }
        />
        <i className='fa fa-search col decoration-col' />
        <button className='btn-bundle-status btn-secondary-v2 clear-filter rounded-pill col decoration-col' onClick={ () => updateFilter('') } type='button'>
          <i className='fa fa-times' /> Clear filter
        </button>
      </div>
    </div>
  );
}
