import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef } from 'react';
import Loading from '../../components/Loading';

BundleVisibilityChecker.propTypes = {
  isParentReady: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
};

export default function BundleVisibilityChecker({ setIsVisible, isParentReady }) {
  const ref = useRef(null);

  const checkIsOnScreen = useCallback((shouldRecheck = true) => {
    if (shouldRecheck) {
      const boundingBox = ref.current?.getBoundingClientRect() || { top: Number.MAX_SAFE_INTEGER };
      if (boundingBox.top <= (window.innerHeight || document.documentElement.clientHeight)) {
        setIsVisible(true);
      }
    }
  }, [setIsVisible]);

  useEffect(() => {
    window.addEventListener('scroll', checkIsOnScreen);
    window.addEventListener('resize', checkIsOnScreen);

    return () => {
      // Remove the listeners when unmounted.
      window.removeEventListener('scroll', checkIsOnScreen);
      window.removeEventListener('resize', checkIsOnScreen);
    };
  }, [checkIsOnScreen]);

  // When the parent elements refresh, this element might get 'pulled' onto the screen.
  useEffect(() => checkIsOnScreen(isParentReady), [isParentReady, checkIsOnScreen]);

  return (
    <div ref={ ref }>
      <Loading />
    </div>
  );
}
