import PropTypes from 'prop-types';

import '../scss/page/_banner.scss';
import BaseBanner from './BaseBanner';

function Banner({ data }) {
  if (!data?.content) {
    return null;
  }

  return (
    <BaseBanner messageId={ data?.id }>
      <p>
        <span dangerouslySetInnerHTML={{ __html: data.content }} />
        { ' ' }
        <a
          className='fw-bold btn-link-underline'
          href={ data.ctaUrl }
          rel='noreferrer noopener'
          target='_blank'
        >
          { data.ctaText }
        </a>
      </p>
    </BaseBanner>
  );
}

Banner.propTypes = {
  data: PropTypes.object,
};

export default Banner;
