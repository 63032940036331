
import PropTypes from 'prop-types';

ManageSlideOut.propTypes = {
  isCardExpanded: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderLineItem: PropTypes.func.isRequired,
  slideOutId: PropTypes.string.isRequired,
  toggleCardExpansion: PropTypes.func.isRequired,
  viewLabel: PropTypes.string.isRequired,
};

export default function ManageSlideOut({ slideOutId, viewLabel, renderLineItem, items, isCardExpanded, toggleCardExpansion }) {
  const alphabetizedItems = items.sort((a, b) => {
    const fullNameA = `${ a.first_name } ${ a.last_name }`;
    const fullNameB = `${ b.first_name } ${ b.last_name }`;
    return fullNameA.localeCompare(fullNameB);
  });

  return (
    <div className='row' >
      <input
        checked={ isCardExpanded }
        className='slide-out-toggle'
        id={ `toggle-${ slideOutId }` }
        onChange={ toggleCardExpansion }
        type='checkbox'
      />
      <label className='letter-info' htmlFor={ `toggle-${ slideOutId }` }>
        <span className='text-gray'>{ viewLabel }</span>
        <i className='fa fa-chevron-down slide-out-arrow' />
        <i className='fa fa-chevron-up slide-out-arrow' />
      </label>
      <div className='slide-out-list' >
        { isCardExpanded && alphabetizedItems.map(renderLineItem) }
      </div>
    </div>
  );
}
