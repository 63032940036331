import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import useLocalStorageState from '../lib/hooks/useLocalStorageState';

import '../scss/page/_banner.scss';
import closeIcon from '../images/page/icon-close.svg';

function getLocalStorageKey(id) {
  return `vfBannerRendered-${ id }`;
}
function BaseBanner({ children, messageId }) {
  const localStorageKey = useMemo(
    () => getLocalStorageKey(messageId),
    [messageId],
  );
  const [hasBannerRendered, setHasBannerRendered] = useLocalStorageState(
    localStorageKey,
    false,
  );
  const [isBannerVisible, setIsBannerVisible] = useState(!hasBannerRendered);

  const toggleIsBannerVisible = () => {
    setIsBannerVisible(false);
    setHasBannerRendered(true);
  };

  if (!isBannerVisible) {
    return null;
  }

  return (
    <div className='announcement-banner'>
      { children }
      <button aria-label='Close' className='btn' onClick={ toggleIsBannerVisible } >
        <img alt='Close this announcement' aria-hidden src={ closeIcon } />
      </button>
    </div>
  );
}
BaseBanner.propTypes = {
  children: PropTypes.node.isRequired,
  messageId: PropTypes.any.isRequired,
};

export default BaseBanner;
