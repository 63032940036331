import axios from 'axios';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';

import Lightbox from './Lightbox';
import Banner from './Banner';
import logger from '../lib/logger';
import Logo from '../components/Logo';
import ProfileNavItem from './ProfileNavItem';
import { AuthContext } from '../contexts/AuthContext';
import ClickToAgree from '../components/ClickToAgree';

import '../scss/page/_header.scss';
import '../scss/page/_profile-nav-item.scss';

class Header extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      navBarCollapsed: true,
      announcementLightbox: null,
      announcementBanner: null,
      resetLightBox: false,
    };
  }

  componentDidMount() {
    this.getCurrentAnnouncement().catch(logger.error);
    this.getCurrentBannerAnnouncement().catch(logger.error);
  }

  async getCurrentBannerAnnouncement() {
    const result = await axios.get(`${ process.env.REACT_APP_API_URL }/v1/announcements/banner/current`);
    if (result && result.data) {
      const announcementBanner = { ...this.state.announcementBanner, ...result.data };
      this.setState({ announcementBanner });
    } else {
      this.setState({ announcementBanner: null });
    }
  }

  async getCurrentAnnouncement() {
    const result = await axios.get(`${ process.env.REACT_APP_API_URL }/v1/announcements/lightbox/current`);
    const announcementLightbox = { ...this.state.announcementLightbox };

    announcementLightbox.id = result.data.id;
    announcementLightbox.header = result.data.header;
    announcementLightbox.content = result.data.content;
    announcementLightbox.ctaText = result.data.ctaText;
    announcementLightbox.ctaUrl = result.data.ctaUrl;
    announcementLightbox.secondaryHeader = result.data.secondaryHeader;
    announcementLightbox.secondaryContent = result.data.secondaryContent;
    announcementLightbox.navBarText = result.data.navBarText;

    this.setState({ announcementLightbox });
  }

  login = () => this.context.login(true);

  toggleNavbarCollapse = () => {
    this.setState({ navBarCollapsed: !this.state.navBarCollapsed });
  };

  resetAnnouncement = () => {
    this.setState({ resetLightBox: true });
    this.forceUpdate();
  };

  onLightboxClose = () => {
    this.setState({ resetLightBox: false });
  };

  render() {
    let authenticatedNav;
    let showAdmin;
    if (this.context.isAuthenticated() && this.props.path !== '/verify') {
      authenticatedNav = true;
      showAdmin = this.context.isAdmin();
    }

    // zIndex is specified here so that in the admin console, the Header does not cover a full screen modal
    return (
      <header>
        <ClickToAgree auth={ this.context } />
        <nav className='navbar navbar-expand-lg' style={{ zIndex: 1 }}>
          <div className='logo navbar-brand' id='votefwdLogo'>
            <Logo />
          </div>
          <button
            aria-label='Toggle navigation'
            className='navbar-toggler'
            onClick={ this.toggleNavbarCollapse }
            type='button'
          >
            <span className='navbar-toggler-icon'><i className='fa fa-bars' /></span>
          </button>
          <div className={ `${ this.state.navBarCollapsed ? 'collapse' : '' } navbar-collapse flex-row-reverse` } id='navbarNavAltMarkup'>
            { authenticatedNav
              ? (
                <div className='navbar-nav'>
                  <NavLink activeClassName='active' className='nav-item nav-link text-white' to='/about-us'>About Us</NavLink>
                  <NavLink activeClassName='active' className='nav-item nav-link text-white' to='/dashboard'>Dashboard</NavLink>
                  <NavLink activeClassName='active' className='nav-item nav-link text-white' to='/history'>History</NavLink>
                  <NavLink activeClassName='active' className='nav-item nav-link text-white' to='/campaigns'>Campaigns</NavLink>

                  <NavLink activeClassName='active' className='nav-item nav-link text-white' to='/instructions'>How-to</NavLink>
                  { this.state.announcementLightbox && this.state.announcementLightbox.navBarText
                    ? (
                      <div className='btn-link nav-item nav-link text-white' onClick={ this.resetAnnouncement }>
                        <NotificationsActiveIcon />&nbsp;
                        <span className='d-none d-xl-inline-block'>
                          { this.state.announcementLightbox.navBarText }
                        </span>
                      </div>
                      )
                    : null }
                  { showAdmin
                    ? (
                      <Link className='nav-item nav-link text-white' to='/admin'>
                        Admin
                      </Link>
                      )
                    : null }
                  <div className='nav-item nav-button nav-link me-0'>
                    <ProfileNavItem auth={ this.context } />
                  </div>
                  <div className='nav-item nav-button nav-link me-0'>
                    <a className='btn btn-primary-blue' href='https://secure.actblue.com/donate/vote-forward-c4?refcode=webheader' rel='noreferrer noopener' target='_blank'>
                      Donate
                    </a>
                  </div>
                </div>
                )
              : (
                <div className='navbar-nav'>
                  <NavLink activeClassName='active' className='nav-item nav-link text-white' to='/about-us'>About us</NavLink>
                  <NavLink activeClassName='active' className='nav-item nav-link text-white' to='/campaigns'>Campaigns</NavLink>
                  <NavLink activeClassName='active' className='nav-item nav-link text-white' to='/instructions'>How-to</NavLink>
                  { this.state.announcementLightbox && this.state.announcementLightbox.navBarText && (
                    <div className='nav-item nav-link text-white' id='nav-view-lightbox' onClick={ this.resetAnnouncement }>
                      <NotificationsActiveIcon /><span className='d-none d-xl-inline-block'>&nbsp; { this.state.announcementLightbox.navBarText }</span>
                    </div>
                  ) }
                  {
                    this.context.isAuthenticated()
                    && <NavLink activeClassName='active' className='nav-item nav-link text-white' to='/dashboard'>Dashboard</NavLink>
                  }
                  {
                    this.context.isAuthenticated()
                    && <NavLink activeClassName='active' className='nav-item nav-link text-white' to='/history'>History</NavLink>
                  }
                  <div className='nav-item nav-button nav-link me-0'>
                    {
                      this.context.isAuthenticated()
                        ? <ProfileNavItem auth={ this.context } />
                        : <button className='btn btn-primary-white me-3' onClick={ this.login }>Log In</button>
                    }
                  </div>
                  <div className='nav-item nav-button nav-link me-0'>
                    <a className='btn btn-primary-blue' href='https://secure.actblue.com/donate/vote-forward-c4?refcode=webheader' rel='noreferrer noopener' target='_blank'>
                      Donate
                    </a>
                  </div>
                </div>
                ) }
          </div>
        </nav>
        { this.props.showBanner && this.state.announcementBanner ? <Banner data={ this.state.announcementBanner } /> : null }
        { this.state.announcementLightbox ? <Lightbox announcement={ this.state.announcementLightbox } onLightboxClose={ this.onLightboxClose } resetLightBox={ this.state.resetLightBox } /> : null }
      </header>
    );
  }
}

Header.defaultProps = {
  showBanner: true,
};

Header.propTypes = {
  path: PropTypes.string,
  showBanner: PropTypes.bool,
};

export default Header;
