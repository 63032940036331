
import { Fragment } from 'react';
import { Switch } from 'react-router-dom';

import AdminImport from './admin/AdminImport';
import AdminRoute from './AdminRoute';
import AuthError from './AuthError';
import BigSend from './big-send/BigSend';
import Contact from './Contact';
import Dashboard from './pages/Dashboard';
import District from './District';
import ElectionHistory from './ElectionHistory';
import EmailPreferences from './EmailPreferences';
import GA from './lib/GoogleAnalytics';
import GuidedLogin from './components/GuidedLogin';
import Impact from './pages/Impact';
import Impact2020 from './pages/Impact2020';
import KlaviyoForm from './pages/KlaviyoForm';
import LetterKitsOverview from './pages/LetterKitsOverview';
import Loading from './components/Loading';
import LoggedInRoute from './lib/routes/LoggedInRoute';
import OptOut from './pages/OptOut';
import PageRoute from './lib/routes/PageRoute';
import Partner from './partners/Partner';
import PledgeToVote from './pledge-to-vote/PledgeToVote';
import Profile from './Profile';
import ReactAdmin from './admin/Admin';
import RelinquishLetters from './RelinquishLetters';
import Route from './lib/routes/Route';
import ExternalRedirect from './lib/routes/ExternalRedirect';
import Users from './admin/Users';
import Verify from './Verify';
import VerifyEmail from './VerifyEmail';
import VerifyEmailRequest from './VerifyEmailRequest';
import { useAuth } from './contexts/AuthContext';


const helpUrl = 'https://help.votefwd.org';

function Routes() {
  const auth = useAuth();

  function handleAuthentication({ location }) {
    if (/code=/.test(location.search)) {
      auth.handleAuthentication({ location });
    }
  }

  return (
    <Fragment>
      { GA.init() && <GA.RouteTracker /> }
      <Switch>
        <Route
          exact
          path='/callback'
          render={ (props) => {
            handleAuthentication(props);
            return <Loading { ...props } style={{ display: 'flex' }} />;
          } }
        />
        <Route
          exact
          path='/auth-error'
          render={ (props) => <AuthError auth={ auth } { ...props } /> }
        />

        <LoggedInRoute component={ Dashboard } exact path='/dashboard' />

        <Route component={ GuidedLogin } exact path='/dashboard/login/:compositeUpdateToken' />

        <LoggedInRoute component={ Dashboard } path='/dashboard/:districtId' />

        <LoggedInRoute component={ ElectionHistory } path='/history' />
        <Route
          exact
          path='/district'
          render={ (props) => <District auth={ auth } { ...props } /> }
        />
        <Route
          path='/district/:id'
          render={ (props) => <District auth={ auth } { ...props } /> }
        />
        <Route
          exact
          path='/email-preferences/:composite_update_token'
          render={ (props) => <EmailPreferences auth={ auth } { ...props } /> }
        />
        <Route
          exact
          path='/relinquish-letters/:composite_update_token'
          render={ (props) => <RelinquishLetters auth={ auth } { ...props } /> }
        />
        <LoggedInRoute component={ Profile } path='/profile' />
        <Route
          exact
          path='/register-user'
          render={ (props) => <VerifyEmailRequest purpose='register.user' { ...props } /> }
        />
        <Route
          exact
          path='/forgot-password'
          render={ (props) => <VerifyEmailRequest purpose='forgot.password' { ...props } /> }
        />
        <Route
          exact
          path='/update-preferences'
          render={ (props) => <VerifyEmailRequest purpose='update.preferences' { ...props } /> }
        />
        <Route
          exact
          path='/verify-email/:email'
          render={ (props) => <VerifyEmailRequest purpose='verify.email' { ...props } /> }
        />
        <Route
          exact
          path='/verify-email'
          render={ (props) => <VerifyEmailRequest purpose='verify.email' { ...props } /> }
        />
        <Route
          exact
          path='/password-updated'
          render={ (props) => <VerifyEmailRequest purpose='password.updated' { ...props } /> }
        />
        <Route
          path='/confirm-email/:purpose/:emailToken'
          render={ (props) => <VerifyEmail auth={ auth } { ...props } /> }
        />
        <LoggedInRoute component={ Verify } exact path='/verify' />
        <AdminRoute auth={ auth } component={ ReactAdmin } exact path='/admin' />
        <Route
          path='/admin/email-preferences-import'
          render={ (props) => (
            <AdminImport
              auth={ auth }
              endpoint='email-preferences'
              title='Backfill Email Preferences'
              { ...props }
            />
          ) }
        />
        <Route
          path='/admin/partners-import'
          render={ (props) => (
            <AdminImport
              auth={ auth }
              endpoint='partners'
              title='Insert/Update partners'
              { ...props }
            />
          ) }
        />
        <Route
          path='/admin/user-partners-import'
          render={ (props) => (
            <AdminImport
              auth={ auth }
              endpoint='user-partners'
              title='Insert/Update user partners'
              { ...props }
            />
          ) }
        />
        <Route
          path='/admin/delete-voters'
          render={ (props) => (
            <AdminImport
              auth={ auth }
              endpoint='delete-voters'
              title='Delete Voters'
              { ...props }
            />
          ) }
        />
        <Route
          path='/admin/partners/:partner/users'
          render={ (props) => <Users auth={ auth } { ...props } showHeader /> }
        />

        <PageRoute
          component={ () => {
            window.location.href = `${ process.env.REACT_APP_NEXT_JS_URL }/terms-of-use`;
            return null;
          } }
          exact
          path='/terms-of-use'
        />
        <Route
          component={ () => {
            window.location.href = helpUrl;
            return null;
          } }
          exact
          path='/faq'
        />
        <Route
          component={ () => {
            window.location.href = helpUrl;
            return null;
          } }
          exact
          path='/covid-faq'
        />

        <PageRoute
          component={ () => {
            window.location.href = `${ process.env.REACT_APP_NEXT_JS_URL }/events`;
            return null;
          } }
          exact
          path='/events'
        />
        <PageRoute
          component={ () => {
            window.location.href = `${ process.env.REACT_APP_NEXT_JS_URL }/blog`;
            return null;
          } }
          exact
          path='/blog'
        />
        <ExternalRedirect
          exact
          path='/posts/:blogId?'
        />
        <PageRoute
          component={ () => {
            window.location.href = `${ process.env.REACT_APP_NEXT_JS_URL }/contact`;
            return null;
          } }
          exact
          path='/contact'
        />
        <Route
          exact
          path='/contact'
          render={ (props) => <Contact auth={ auth } { ...props } /> }
        />
        <Route
          component={ () => {
            window.location.href = helpUrl;
            return null;
          } }
          exact
          path='/support'
        />
        <PageRoute
          component={ () => {
            window.location.href = `${ process.env.REACT_APP_NEXT_JS_URL }/research`;
            return null;
          } }
          exact
          path='/labs'
        />
        <PageRoute
          component={ () => {
            window.location.href = `${ process.env.REACT_APP_NEXT_JS_URL }/research`;
            return null;
          } }
          exact
          path='/research'
        />
        <PageRoute
          component={ () => {
            window.location.href = `${ process.env.REACT_APP_NEXT_JS_URL }/partners`;
            return null;
          } }
          exact
          path='/partners'
        />

        <Route
          exact
          path='/:partner/district/:id'
          render={ (props) => <District auth={ auth } { ...props } /> }
        />
        <PageRoute
          component={ () => {
            window.location.href = `${ process.env.REACT_APP_NEXT_JS_URL }/bigsend`;
            return null;
          } }
          exact
          path='/bigsend'
        />
        <Route component={ BigSend } path='/bigsend(atwork|oncampus)?/:partner?' />


        <ExternalRedirect exact path='/campaigns' />
        <ExternalRedirect exact path='/' />

        <PageRoute component={ Impact } exact path='/impact' />
        <PageRoute component={ Impact2020 } exact path='/impact2020' />
        <PageRoute
          component={ () => {
            window.location.href = `${ process.env.REACT_APP_NEXT_JS_URL }/about-us`;
            return null;
          } }
          exact
          path='/about-us'
        />
        <PageRoute
          component={ () => {
            window.location.href = `${ process.env.REACT_APP_NEXT_JS_URL }/instructions`;
            return null;
          } }
          exact
          path='/instructions'
        />
        <ExternalRedirect
          exact
          path='/goal-page'
        />
        <ExternalRedirect
          exact
          path='/goal-page/:slug?'
        />
        <ExternalRedirect
          exact
          path='/my-page/:slug?/manage'
        />
        <PageRoute
          component={ () => {
            window.location.href = `${ process.env.REACT_APP_NEXT_JS_URL }/my-page`;
            return null;
          } }
          exact
          path='/my-page'
        />
        <PageRoute component={ KlaviyoForm } exact path='/memory-bank-2020' />
        <PageRoute component={ LetterKitsOverview } exact path='/letter-kits' />
        <PageRoute
          component={ () => {
            window.location.href = `${ process.env.REACT_APP_NEXT_JS_URL }/open-positions`;
            return null;
          } }
          exact
          path='/open-positions'
        />
        <PageRoute component={ OptOut } exact hasMinimalHeader path='/opt-out/:code?' />
        <PageRoute component={ PledgeToVote } exact hasMinimalHeader path={ ['/pledge', '/vote'] } />
        <PageRoute
          component={ () => {
            window.location.href = `${ process.env.REACT_APP_NEXT_JS_URL }/privacy-policy`;
            return null;
          } }
          exact
          path='/privacy-policy'
        />
        <PageRoute
          component={ () => {
            window.location.href = `${ process.env.REACT_APP_NEXT_JS_URL }/program-overview`;
            return null;
          } }
          exact
          path='/program-overview'
        />
        <Route component={ Partner } path='/:partner' />
      </Switch>
    </Fragment>
  );
}

export default Routes;

