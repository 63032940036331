import { useBundleDataCounts, useModifyAndDownloadState } from '../../lib/queries/queries';
import ManageLettersColumn from './ManageLettersColumn';
import '../../scss/dashboard/_manage-letters.scss';
import LetterFilter from './LetterFilter';
import PropTypes from 'prop-types';
import Loading from '../../components/Loading';

ManageLetters.propTypes = {
  canAdoptSocial: PropTypes.bool.isRequired,
  letterFilter: PropTypes.string,
  updateFilter: PropTypes.func,
};

function ManageLetters({ letterFilter, updateFilter, canAdoptSocial }) {
  const modifyAndDownloadState = useModifyAndDownloadState();
  const { isLoading, isSuccess, data: countData } = useBundleDataCounts();

  if (isLoading) {
    return (<Loading />);
  }
  if (!isSuccess || !countData) {
    // TODO: show an error bar.
    return null;
  }

  const subtitle = `Track the voters you've adopted${ canAdoptSocial ? ' individually or with your party' : '' }, along with your letter-writing process, using the bundles listed below.`;

  return (
    <div className='px-2 px-sm-5 pb-5 user-letters' data-testid='manage-letters'>
      <div className='row mb-4'>
        <div className='col-lg-12 col-xl-6 pb-4' >
          <div className='row mb-2'>
            <div className='col'>
              <span className='pt-2 h2'>
                My Letters
              </span>
              <span className='fs-4 fw-bold'>
                { ` (${ countData.total_voters } letters in ${ countData.total_bundles } bundles)` }
              </span>
            </div>
          </div>
          <div className='row'>
            <span className='fs-6'>
              { subtitle }
            </span>
          </div>
        </div>
        <div className='col-lg-12 col-xl-6'>
          <LetterFilter className='p-2 mr-3 rounded-pill letter-filter' letterFilter={ letterFilter } updateFilter={ updateFilter } />
        </div>
      </div>
      <div className='row'>
        <ManageLettersColumn
          bundleCount={ countData.unprepped_bundles }
          canAdoptSocial={ canAdoptSocial }
          letterCount={ countData.unprepped_voters }
          letterFilter={ letterFilter }
          modifyAndDownloadState={ modifyAndDownloadState }
          status='UNPREPPED'
        />
        <ManageLettersColumn
          bundleCount={ countData.prepped_bundles }
          canAdoptSocial={ canAdoptSocial }
          letterCount={ countData.prepped_voters }
          letterFilter={ letterFilter }
          modifyAndDownloadState={ modifyAndDownloadState }
          status='PREPPED'
        />
        <ManageLettersColumn
          bundleCount={ countData.sent_bundles }
          canAdoptSocial={ canAdoptSocial }
          letterCount={ countData.sent_voters }
          letterFilter={ letterFilter }
          modifyAndDownloadState={ modifyAndDownloadState }
          status='SENT'
        />
      </div>
    </div>
  );
}

export default ManageLetters;
