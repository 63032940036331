import PropTypes from 'prop-types';
import { useDownloadLetterState } from '../../lib/queries/queries';
import SlideOutRow from './common/SlideOutRow';

function LetterListItem({ letter, mailOnDate, modifyAndDownloadState, status, isReady }) {
  const downloadState = useDownloadLetterState({ letterId: letter.id, modifyAndDownloadState });
  return (
    <SlideOutRow
      downloadIconOnly={ status === 'PREPPED' }
      downloadState={ downloadState }
      isReady={ isReady }
      lettersToMutate={ [letter] }
      mailOnDate={ mailOnDate }
      modifyAndDownloadState={ modifyAndDownloadState }
      segmentTrackIds={{ letterId: letter.hashid }}
      status={ status }
    >
      <div className='col letter-text fill-row bundle-cell-contents'>
        <span className='fill-row fw-bold'>{ `${ letter.first_name } ${ letter.last_name }` }</span>
      </div>
      <div className='col letter-text fill-row bundle-cell-contents'>
        { /* separate spans to support eventual overflow behavior, where state abbr. takes precedence. */ }
        <span>{ `${ letter.city }` }</span>
        <span>{ `, ${ letter.state }` }</span>
      </div>
    </SlideOutRow>
  );
}

LetterListItem.propTypes = {
  isReady: PropTypes.bool.isRequired,
  letter: PropTypes.object.isRequired,
  mailOnDate: PropTypes.string.isRequired,
  modifyAndDownloadState: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
};

export default LetterListItem;
